<template>
	<div class="financeinformation">
		<div class="financeinformation_top">
			<div class="financeinformation_mybreadcrumb">
				当前位置： <app-mybreadcrumb />
			</div>

			<div class="financeinformation_inp">
				<el-input placeholder="输入关键字搜索" suffix-icon="el-icon-search" v-model="keyword" @change='searchData'></el-input>
			</div>
		</div>

		<section class="financeinformation_content">
			<div class="financeinformation_list"  v-if='flag == 1'>
				<div class="financeinformation_item" v-for='item in listsItem' @click='toInfoDetail(item.id)'>
					<div class="financeinformation_message" :title='item.title'>{{item.title}}</div>
					<div class="financeinformation_time">{{item.create_time}}</div>
				</div>

				<!-- 分页 -->
				<div class="financeinformation_list_reportscreen">
					<div class="financeinformation_list_footer">
				        <div class="financeinformation_list_mar">
				        	<app-mypaginations :pagSize='paginations' :allData='informationList' @setHandleCurrent='handleCurrent' @setHandleSize='handleSize' @setPage='setPage' @setSize='setSize' />
				        </div>
			        </div>
				</div>
			</div>

			<el-empty description="数据为空" v-if='flag == 0' :image-size="200"></el-empty>
		</section>
	</div>
</template>

<script>
	import MyBreadcrumb from '@/components/MyBreadcrumb/mybreadcrumb'
	import MyPaginations from '@/components/MyPaginations/mypaginations.vue'

	export default {
		data() {
			return {
				paginations: {
		          page_index: 1, // 当前位于哪页
		          total: 10, // 总数
		          page_size: 10, // 1页显示多少条
		          page_sizes: [10, 20, 50, 90], //每页显示多少条
		          layout: "total, sizes, prev, pager, next, jumper" // 翻页属性
		        },
		        count: 0,
		        keyword: '',
				informationList: [],
				listsItem: [],
				flag: 1
			}
		},
		components: {
			'app-mybreadcrumb': MyBreadcrumb,
			'app-mypaginations': MyPaginations
		},
		methods: {
			searchData() {
				this.paginations.page_index = 1

				this.getData()
			},
			toInfoDetail(id) {
				this.$router.push({path: '/informationdetail', query: {ids: id}})
			},
			setPage(data) {
				this.paginations.page_index = data

				this.getData()
			},
			setSize(data) {
				this.paginations.page_index = 1
				this.paginations.page_size = data

				this.getData()
			},
			handleCurrent(item) {
		    	this.listsItem = item
		    },
		    handleSize(item) {
		    	this.listsItem = item
		    },
		    //重置分页
		    resetPaginations() {
		      // 总页数
		      this.paginations.total = this.count
		      // 设置默认分页数据
		      this.listsItem = this.informationList.filter((item, index) => {
		        return index < this.paginations.page_size
		      })
		    },
		    getData() {
		    	let data = {
		    		page: this.paginations.page_index,
		    		limit: this.paginations.page_size,
		    		keyword: this.keyword
		    	}

		    	this.$get('/news_list', data).then(res => {
		    		if(!res.data.result) {
						this.informationList = []
						this.count = 0
						
						this.flag = 0
					}else {
						this.informationList = res.data.result.length == 0 ? [] : res.data.result
						this.count = res.data.count
						
						this.flag = 1
					}

					this.resetPaginations()
		    	})
		    }
		},
		mounted() {
			this.getData()
		}
	}
</script>

<style scoped>
	@import './financeinformation.css';
</style>